import React from "react";
import { Table, Button, Modal, Row, Col,  } from "react-materialize";
import Divider from "react-materialize/lib/Divider";
import lokal11 from "./svg/M.1.1 .svg"
import lokal12 from "./svg/M12.svg"
import lokal21 from "./svg/M12.svg"
import lokal22 from "./svg/M.2.2.svg"
import lokal23 from "./svg/M.2.3.svg"
import lokal24 from "./svg/M.2.3.svg"
import lokal25 from "./svg/M25.svg"
import lokal26 from "./svg/M26.svg"
import lokal27 from "./svg/M27.svg"
import lokal28 from "./svg/M28.svg"
import lokal31 from "./svg/M.3.2.svg"
import lokal32 from "./svg/M.3.2.svg"
import lokal33 from "./svg/M.3.3.svg"
import lokal34 from "./svg/M.3.3.svg"
import lokal35 from "./svg/M35.svg"
import lokal36 from "./svg/M36.svg"
import lokal37 from "./svg/M37.svg"
import lokal38 from "./svg/M38.svg"
import lokal41 from "./svg/M.3.3.svg"
import lokal42 from "./svg/M.3.3.svg"
import lokal43 from "./svg/M.3.3.svg"
import lokal44 from "./svg/M44.svg"
import lokal45 from "./svg/M45.svg"
import lokal46 from "./svg/M46.svg"
import lokal47 from "./svg/M47.svg"
import lokal51 from "./svg/M51.svg"
import lokal52 from "./svg/M52.svg"
import lokal53 from "./svg/M53.svg"



const Lista = [
  {
    Rzut: (<Row>RZUT</Row>)
      
    
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal11} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button className="red" node="button"  small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal12} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1sad"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal21} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M123d"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal22} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1cvf"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal23} width="100%" />
         
        </Row>
      </Modal>
    ),
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="M1s"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal24} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },


  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="25"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal25} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="26"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal26} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="27"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal27} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="28"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal28} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="31"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal31} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="31"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal31} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="31"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal31} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="31"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal31} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="35"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal35} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="36"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal36} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="37"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal37} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="38"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal38} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  
  
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="41"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal41} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="42"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal42} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="43"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button disabled className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal43} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="44"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal44} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="45"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal45} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="46"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal46} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="47"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal47} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="51"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal51} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="52"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal52} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  {
    Rzut: (
      <Modal
      className="modalid"
        actions={[
          <Button flat modal="close" node="button" waves="green">
            Zamknij
          </Button>,
        ]}
        bottomSheet={false}
        fixedFooter={false}
        header={"Rzut "}
        id="53"
        open={false}
        options={{
          dismissible: true,
          endingTop: "10%",
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: "4%",
        }}
        trigger={
          <Button  className="red" node="button"   small>
            Rzut
          </Button>
        }
      >
        <Row>
          <Divider />  <img src={lokal53} width="100%" />
         
        </Row>
      </Modal>
    ),
  
  
  },
  
  
  
  
  
  
  
];
export default Lista;
